import React from "react"
import aboutVideo from "../../video/about.mp4"

const VideoSection = () => {
    return (
        <section>
            <div className="container-fluid relative">
                <div className="bg-light half-bg" id="half-bg-section" />
                <div className="row zindex-1">
                    <div className="col-md-1" />
                    <div className="col-md-10">
                        <div className="about-video br-10 relative">
                            <video
                                id="about-video"
                                preload="metadata"
                                autoPlay
                                muted
                                loop
                                playsInline
                                controls
                                className="br-10"
                                src={aboutVideo}
                            />
                            {/* <div
                                id="play-control"
                                className="abs-full zindex-1 flex-center"
                            >
                                <button id="play-btn" className="btn p-0">
                                    <svg
                                        viewBox="0 0 24 24"
                                        width={100}
                                        height={100}
                                        stroke="#fff"
                                        strokeWidth={1}
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="css-i6dzq1"
                                    >
                                        <circle cx={12} cy={12} r={10} />
                                        <polygon points="10 8 16 12 10 16 10 8" />
                                    </svg>
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="col-md-1" />
            </div>
        </section>
    )
}

export default VideoSection
