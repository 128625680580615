import React, { useRef, useEffect } from "react"
import creative from "../utils/creative"

const CreativeSection = () => {
    const holder = useRef(null)

    useEffect(() => {
        if (holder.current) {
            creative(holder.current)
        }
    }, [])

    return (
        <div
            className="relative mb-3"
            style={{ height: "300px" }}
            id="canvas-holder"
            ref={holder}
        >
            <div className="mask abs-full">
                <Mask />
            </div>
        </div>
    )
}

const Mask = () => (
    <svg
        id="prefix__Layer_1"
        x={0}
        y={0}
        viewBox="0 0 1040 205.1"
        xmlSpace="preserve"
    >
        <style>{".prefix__st0{fill-rule:evenodd;clip-rule:evenodd}"}</style>
        <path
            className="prefix__st0"
            d="M108.7 155.2l24.8 23.3c-2.1 3.1-4.9 6.2-8.3 9.2-10.2 8.8-26.2 16.4-48.5 16.4-43.3 0-75.7-29.3-75.7-72.9 0-43.3 32.4-72.6 75.7-72.6 29.8 0 48.4 13.2 56.8 25.5l-28.7 26.7c-5.5-8-14.3-13.2-26.1-13.2-18.3 0-32.4 12.6-32.4 33.6 0 3.7.4 7.2 1.3 10.4 1.5 5.9 4.4 10.8 8.2 14.5 5 4.9 11.6 8 19.1 8.7 1.2.1 2.5.2 3.8.2 10.5 0 18.7-4.6 24.2-11.1.7-.8 1.3-1.6 1.8-2.4l4 3.7zM239.9 59.4v42.5c-2.6-.6-6.3-1.2-10.9-1.2-11.5 0-26.7 4.9-32.4 12.6v88.3h-44.2V63.1h44.2V80c9.2-11.2 26.7-20.6 43.3-20.6zM381.7 181.7c-9.3 9.7-21.1 16.9-34.5 20.4l.7-.1 33.8-20.3zM539.8 92.8c-8.7-26-34.4-34.1-61.4-34.1-21.2 0-45 6.3-62.8 21.5l7.2 12.6 8.6 15.2c11.2-9.5 25.5-14.6 39.3-14.6 17.8 0 28.1 8 28.1 20.1v16.6c-8.3-10.3-24.7-16.1-43-16.1-7.1 0-14.8 1.2-21.9 4.1-14 5.7-25.7 18-25.7 40.3 0 24.8 16.7 39 34.1 43.8.7.2 1.4.4 2.1.5 3.8.9 7.7 1.3 11.4 1.3 4.3 0 8.5-.4 12.5-1.2 1.8-.3 3.5-.7 5.2-1.2 10.6-2.8 19.6-8.1 25.2-14.9v13.8h44.1V114c.1-8.1-1-15.2-3-21.2zm-41 72.3c-4.6 6.6-14.9 10-24.7 10-11.8 0-22.1-5.2-22.1-16.1 0-10.9 10.3-16.1 22.1-16.1 9.8 0 20.1 3.4 24.7 9.8v12.4zM605.3 201.1c-5.9-2.5-10.7-6.2-14.1-11.1 3.9 4.7 8.7 8.5 14.1 11.1zM656.2 101.5h-28.1v19.6c-1.4-.1-2.8-.2-4.2-.2-18.2 0-33.7 11.5-39.6 27.5v-46.9h-22.9V63.1h22.9V25.2h43.9v37.9h28.1v38.4zM660.2 195.9c-2.8 2.6-6.9 4.9-12.4 6.6-5.1 1.5-11.3 2.5-18.7 2.6 12-1.5 22.4-7.9 29.1-17.2l2 8zM725.5 26.2c0 14-11.2 25.5-25.2 25.5s-25.2-11.5-25.2-25.5S686.3 1 700.3 1s25.2 11.2 25.2 25.2zM891.5 63.1l-12.1 30.7-5.7 14.7-36.7 93.1h-47l-29.8-75.7-12.6-32.1-12-30.7H782l10.9 30.7 9.2 25.7 11.1 31.4 12.6-35.1 8-22 11-30.7zM939.8 116.6c1.4-8 6.3-18.3 18-22.2 3-1 6.5-1.6 10.4-1.6 20.3 0 27.5 14.1 28.7 23.8h-57.1zm28.4 87.4h2c21.2 0 44.1-6 57.9-18.4l-18.6-27.5c-7.7 7.5-24.6 11.8-34.4 11.8-20.1 0-32.1-11.8-34.7-24.7h98.6v-9.5c0-47-29.8-77.2-70.8-77.2-28.1 0-50.7 13.6-63.2 34.1-2 3.3-3.8 6.8-5.2 10.5-3.4 8.6-5.3 18-5.3 27.9 0 44.3 31.8 71.9 73.5 72.8l.2.2zM678.2 201.4V63.1h44.1v138.3"
        />
        <path
            className="prefix__st0"
            d="M395.3 135.8c0-10-1.3-19.2-3.8-27.5-1.7-5.6-3.9-10.8-6.6-15.5-12.1-21.6-33.9-34.1-60.4-34.1-9.1 0-17.7 1.4-25.5 4.1-25.9 11.5-43.9 37.5-43.9 67.8 0 37.8 28.1 69 64.5 73.5 3 .4 6 .5 9 .5 6.4 0 12.7-.8 18.6-2.4 13.3-3.5 25.2-10.7 34.5-20.4l-15.9-23.5c-7.7 7.5-24.7 11.8-34.4 11.8-20.1 0-32.1-11.8-34.7-24.7h98.6v-9.6zm-99.2-19.2c1.7-10 9.2-23.8 28.4-23.8 6.4 0 11.5 1.4 15.5 3.6 8.7 4.8 12.4 13.5 13.1 20.2h-57zM658.1 187.8c-6.7 9.3-17.1 15.8-29.1 17.2-1.7.2-3.4.3-5.2.3-6.6 0-12.9-1.5-18.5-4.2-5.5-2.7-10.3-6.5-14.1-11.1v-.1c-4.6-6.5-7-15.1-7-25.6v-15.9c6-16.1 21.4-27.5 39.6-27.5 1.4 0 2.8.1 4.2.2V152c0 7.1 3.6 12.6 9.8 13.8.8.1 1.6.2 2.5.2 4.9 0 9.7-1.7 11.2-3.4l1.9 7.4 4.7 17.8z"
        />
    </svg>
)


export default CreativeSection
