import React from "react"
import Layout from "../components/common/Layout"
import CreativeSection from "../components/about/CreativeSection"
import BigBang from "../components/about/BigBang"
import VideoSection from "../components/about/VideoSection"
import Tilt from "react-tilt"

const About = () => {
    return (
        <Layout>
            <CreativeSection />
            <VideoSection />
            <BigBang />
            <section className="section-pad">
                <div className="container">
                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <div className="about-loader">
                            <span />
                            <span />
                            <span />
                            <span />
                        </div>
                        <h2 className="text-center font-weight-light">
                            It's hard to kill an idea, once it gets to your head
                        </h2>
                    </div>
                </div>
            </section>
            <section className="section-pad border-top">
                <div className="flex-center text-center flex-column">
                    <h2
                        className="contact-title glitch is-glitching"
                        data-text="Careers"
                    >
                        Careers
                    </h2>
                    <p className="f-32 font-weight-light">
                        It's more than a job!
                    </p>
                </div>
                <div className="container-fluid">
                    <div className="row career-row">
                        <CareerBox>
                            <p className="careertitle">Creative</p>
                            <ul style={{ listStyle: "none" }}>
                                <li>Motion Designer (Interactive)</li>
                                <li>
                                    Associate Creative Director (Interactive)
                                </li>
                                <li>Sr. Interactive Designer / Art Director</li>
                                <li>Interactive Designer</li>
                                <li>Freelance Sr. Interactive Designer</li>
                            </ul>
                        </CareerBox>
                        <CareerBox>
                            <p className="careertitle">Client Services</p>
                            <ul style={{ listStyle: "none" }}>
                                <li>Interactive Producer</li>
                                <li>Project Manager</li>
                            </ul>
                        </CareerBox>
                        <CareerBox>
                            <p className="careertitle">Strategy</p>
                            <ul style={{ listStyle: "none" }}>
                                <li>Senior Brand Strategist / Planner</li>
                                <li>Senior UX Architect</li>
                            </ul>
                        </CareerBox>
                        <CareerBox>
                            <p className="careertitle">Operations</p>
                            <ul style={{ listStyle: "none" }}>
                                <li>Business Development Manager</li>
                            </ul>
                        </CareerBox>
                        <CareerBox>
                            <p className="careertitle">Technology</p>
                            <ul style={{ listStyle: "none" }}>
                                <li>Senior Creative Developer</li>
                                <li>Front-end Web Developer</li>
                                <li>Freelance WordPress Developer</li>
                                <li>Senior PHP Developer</li>
                                <li>Magento Developer</li>
                            </ul>
                        </CareerBox>
                        <CareerBox>
                            <p className="careertitle">Internships</p>
                            <ul style={{ listStyle: "none" }}>
                                <li>"Hungry" Individuals</li>
                            </ul>
                        </CareerBox>
                    </div>
                </div>
            </section>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 contact-left-bg" />
                        <div className="col-md-6">
                            <div className="contact-pad">
                                <h2 className="contact-title mb-4 text-center">
                                    Drop us a line!
                                </h2>
                                <form className="contact-form">
                                    <input
                                        type="text"
                                        placeholder="Type your name"
                                    />
                                    <input
                                        type="email"
                                        placeholder="Type your email"
                                    />
                                    <textarea
                                        placeholder="Type your message"
                                        defaultValue={""}
                                    />
                                    <button className="btn btn-block contact-send-btn">
                                        Send
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

const CareerBox = ({ children }) => {
    return (
        <div className="col-md-4 col-sm-6 mb-4">
            <Tilt
                options={{
                    glare: false,
                    maxTilt: 5,
                    scale: 1,
                    axis: "X",
                }}
                className="careerbox"
            >
                {children}
            </Tilt>
        </div>
    )
}

export default About
