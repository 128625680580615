import React from "react"

const BigBang = () => {
    return (
        <section className="section-pad bg-light" id="big-bang-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h3 className="font-weight-bold mb-3">
                            OUR BIG BANG THEORY
                        </h3>
                        <p>
                            Earlier in the universe, there was a huge explosion
                            of BRANDS! Our Ground rule is simple. “Always
                            targeting superb innovative output." We are
                            performance marketers, working on delivering the
                            best results and understanding the value of strong
                            partnerships. We adore building brands for our
                            “super clients” most of the days, but sometimes we
                            like building our own brand too! Gotta stay sharp,
                            right? Ultimately, we are a Global Ad-Tech company
                            that engages consumers right from the first
                            impression.
                        </p>
                    </div>
                    <div className="col-md-6" />
                </div>
            </div>
        </section>
    )
}

export default BigBang
